<template>
  <div class="auto_home">
    <div class="auto_content">
      <div class="auto_content_size">
        <div style="width: 50px">统计</div>
        <div class="each">
          <div class="icon_bg">
            <i class="iconfont icon-gongsi1"></i>
          </div>
          <div class="each_text">
            <div>企业总数</div>
            <span>{{ info.countTotal }}</span>家
          </div>
        </div>
        <div class="each" @click="getNewListTotal()">
          <div class="icon_bg">
            <i class="iconfont icon-kehuguanli"></i>
          </div>
          <div class="each_text">
            <div>本期用户</div>
            <a>
              <span>{{ info.countPeriod }}</span>家
            </a>
          </div>
        </div>
        <div class="each" @click="getWjz">
          <div class="icon_bg">
            <i class="iconfont icon-kehuguanli"></i>
          </div>
          <div class="each_text">
            <div>异常</div>
            <a><span>{{ info.countYc }}</span>家</a>
          </div>
        </div>
        <div class="each" @click="getNewListTotal(3)">
          <div class="icon_bg">
            <i class="iconfont icon-shouquanyemian1"></i>
          </div>
          <div class="each_text">
            <div>待确认</div>
            <a>
              <span>{{ info.countDqr }}</span>家
            </a>
          </div>
        </div>
      </div>
      <div class="date_select">
        <qzf-search-period v-model:period="listQuery.period" :clearable="false" style="width:100px;margin:0 5px"
          @change="getNewList"></qzf-search-period>
      </div>
    </div>
    <el-tabs v-model="activeName" @tab-click="handleClick" type="border-card">
      <el-tab-pane name="3" label="全部">
      </el-tab-pane>
      <el-tab-pane name="1" label="采集有社保">
      </el-tab-pane>
      <el-tab-pane name="2" label="采集无社保">
      </el-tab-pane>
      <div class="top_select">
        <div>
          <el-button icon="Refresh" plain type="primary" size="small" class="mr-5" @click="getList()"><span style="margin-top: 2px;">刷新</span></el-button>
          <el-input size="small" placeholder="公司名称" v-model.trim="listQuery.name" style="width:160px;"
            @keyup.enter="getList" clearable></el-input>
          <el-button type="primary" size="small" @click="getList" icon="Search" class="search_btn">
            搜索
          </el-button>
          <search @success="getList" @cancel="cancel">
            <el-form style="margin:11px 0px 0px 15px;width:600px" label-width="110px" class="style_form" size="small"
              :inline="true">
              <el-form-item label="纳税人类型：">
                <selecttaxtype v-model:type="listQuery.type" style="width:140px"></selecttaxtype>
              </el-form-item>
              <el-form-item label="选择人员：">
                <selectuser code="bookkeeping" v-model:userId="listQuery.userId" style="width:140px"></selectuser>
              </el-form-item>
              <el-form-item label="税局：">
                <selectcity v-model:citys="listQuery.districtCode" style="width:140px"></selectcity>
              </el-form-item>
              <el-form-item label="授权状态：">
                <selectAccreditStatus v-model:type="listQuery.keepStatus" style="width:140px"></selectAccreditStatus>
              </el-form-item>
              <el-form-item label="采集状态：">
                <autoHomeSelect v-model:modelValue="listQuery.sheBaoCjStatus" placeholder="请选择采集状态" loadingText="采集中" />
              </el-form-item>
              <el-form-item label="确认状态：">
                <autoHomeSelect v-model:modelValue="listQuery.sheBaoqrStatus" placeholder="请选择确认状态" type="qr" />
              </el-form-item>
              <el-form-item label="是否申报社保：">
                <el-select v-model="listQuery.whetherSheBao" style="width:140px">
                  <el-option label="全部" :value="0"></el-option>
                  <el-option label="否" :value="3"></el-option>
                  <el-option label="是" :value="1"></el-option>
                </el-select>
              </el-form-item>
            </el-form>
          </search>
        </div>
        <div>
          <el-button @click="confirmAll()" type="success" size="small" icon="Checked">申报确认</el-button>
          <el-button @click="confirmAllPay()" type="primary" plain size="small" icon="Checked">扣款确认</el-button>

          <el-button type="primary" size="small" @click="copyTax" :disabled="!$buttonStatus('sktj_fzsk')"
            icon="DocumentCopy">复制社保
          </el-button>
          <el-button-group style="margin-left:12px">
            <el-button type="success" size="small" @click="sendTax" :loading="sendLoading"
              :disabled="!$buttonStatus('sktj_fzsk')"><i class="iconfont icon-weixin"></i>&nbsp;微信群发确认
            </el-button>
            <el-button type="success" size="small" @click="bindWechat" :disabled="!$buttonStatus('sktj_fzsk')">绑定微信
            </el-button>
          </el-button-group>
        </div>
      </div>
      <el-table stripe :data="list" :height="contentStyleObj" style="width: 100%" border
        @selection-change="handleSelectionChange" v-loading="loading">
        <template #empty>
          <el-empty :image-size="150" description="没有数据"></el-empty>
        </template>
        <el-table-column align="center" prop="id" type="selection" width="55" />
        <el-table-column prop="name" label="公司名称" min-width="240" fixed="left">
          <template #default="scope">
            <TagNameCopy :row="scope.row" idKey="comId">
              <template v-slot>
                <el-tooltip placement="top" effect="dark">
                  <template #content>
                    <div>{{ scope.row.wxNc ? `已绑定：${scope.row.wxNc}` : '暂未绑定微信昵称或群聊名称，点击绑定' }}</div>
                  </template>
                  <i class="iconfont icon-weixintuisong" :style="{ color: !scope.row.wxNc ? '#888' : '' }"
                    @click="setWechat(scope.row)"></i>
                </el-tooltip>
              </template>
            </TagNameCopy>
          </template>
        </el-table-column>
        <el-table-column label="采集状态" min-width="170">
          <template #default="scope">
            <div class="item_icon">
              <p>
                <i :class="$filterAutoStatusIcon(scope.row.shebaocj_status)"></i>
                <el-tooltip effect="dark" :disabled="!scope.row.shebaocj_err" placement="top-start">
                  <template #content>
                    <div v-html="scope.row.shebaocj_err"></div>
                  </template>
                  <div style="display: inline-block;">
                    <span :class="$filterAutoStatusWord(scope.row.shebaocj_status)">{{
                      $filterAutoStatusText(scope.row.shebaocj_status, 'cj') }}</span>
                    <i class="iconfont icon-wenhao" v-if="scope.row.shebaocj_err"
                      style="color:#f56c6c;margin:0 0 0 4px;"></i>
                  </div>
                </el-tooltip>
              </p>

            </div>
          </template>
        </el-table-column>
        <el-table-column label="采集数据" min-width="170">
          <template #default="scope">
            <div class="item_icon" v-if="scope.row.shebaocj_status == 3">
              <div>
                人数：{{ scope.row.peopleCount }}&nbsp;&nbsp;&nbsp;&nbsp;金额：{{ scope.row.amount }}
              </div>
              <div>
                <el-button @click="checkShebao(scope.row)" :disabled="!$buttonStatus('zdjz_ck')" size="small" link
                  style="padding: 4px 3px;margin-left: 0;">查看</el-button>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="申报确认状态" min-width="120">
          <template #default="scope">
            <div class="item_icon">
              <p>
                <i :class="$filterAutoStatusIcon(scope.row.shebaoqr_status)"></i>
                <el-tooltip effect="dark" :disabled="!scope.row.shebaoqr_err" placement="top-start">
                  <template #content>
                    <div v-html="scope.row.shebaoqr_err"></div>
                  </template>
                  <div style="display: inline-block;">
                    <span :class="$filterAutoStatusWord(scope.row.shebaoqr_status)">{{
                      $filterAutoStatusText(scope.row.shebaoqr_status, 'skqr') }}</span>
                    <i class="iconfont icon-wenhao" v-if="scope.row.shebaoqr_err"
                      style="color:#f56c6c;margin:0 0 0 4px;"></i>
                  </div>
                </el-tooltip>
              </p>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="申报确认人" width="90">
          <template #default="scope">
            <div v-if="[3, 6].includes(scope.row.shebaoqr_status)">{{ scope.row.billConfirmUserName ?
              scope.row.billConfirmUserName : '系统自动' }}</div>
          </template>
        </el-table-column>
        <el-table-column label="扣款确认状态" min-width="120">
          <template #default="scope">
            <div class="item_icon">
              <p>
                <i :class="$filterAutoStatusIcon(scope.row.shebaokkqr_status)"></i>
                <el-tooltip effect="dark" :disabled="!scope.row.shebaokkqr_err" placement="top-start">
                  <template #content>
                    <div v-html="scope.row.shebaokkqr_err"></div>
                  </template>
                  <div style="display: inline-block;">
                    <span :class="$filterAutoStatusWord(scope.row.shebaokkqr_status)">{{
                      $filterAutoStatusText(scope.row.shebaokkqr_status, 'qr') }}</span>
                    <i class="iconfont icon-wenhao" v-if="scope.row.shebaokkqr_err"
                      style="color:#f56c6c;margin:0 0 0 4px;"></i>
                  </div>
                </el-tooltip>
              </p>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="会计" align="center" min-width="60">
          <template #default="scope">
            <DynamicPopover>
              <template #reference>
                <div @click="poper(scope.row)" style="cursor: pointer">
                  <i class="iconfont icon-huiji"></i>
                </div>
              </template>
              <KjTable :list="gridData" />
            </DynamicPopover>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <qzf-pagination v-show="total > 0" :total="total" v-model:page="listQuery.page" v-model:limit="listQuery.limit"
          @pagination="getList" />
      </div>
    </el-tabs>

  </div>
  <abnormalCom ref="abnormalCom" />
  <!-- 统计 -->
  <autoShebaoDetail ref="autoShebaoDetail" />
  <loginTaxAlert ref="loginTaxAlert" title="微信转发助手"></loginTaxAlert>
  <wechatMessage ref="wechatMessage" @success="getList" />
  <shebaoVerifySelect ref="shebaoVerifySelect" @success="getList" />
</template>

<script>
import { autoSocialQrList, automaticSocialStat, confirmAutomaticShebao } from "@/api/automatic";
import { currentAccountPeriod } from '@/utils'
import { companyUserList, companyEaSetWxNc } from '@/api/company'
import selecttaxtype from "@/components/Screening/selecttaxtype";
import selectcity from "@/components/Screening/selectcity";
import selectAccreditStatus from "@/components/Screening/selectAccreditStatus";
import TagNameCopy from '@/components/table-cell-render/company-name/TagNameCopy'
import abnormalCom from './components/abnormalCom.vue'
import DynamicPopover from "@/components/DynamicPopover/DynamicPopover";
import KjTable from "@/components/table-cell-render/KjTable";
import autoShebaoDetail from "./components/autoShebaoDetail.vue";
import autoHomeSelect from "./components/autoHomeSelect.vue";
import axios from "axios";
import wechatMessage from "./components/wechatMessage.vue";
import shebaoVerifySelect from "./components/shebaoVerifySelect.vue";
export default {
  name: "shebaoConfirm",
  components: {
    selecttaxtype,
    selectcity,
    selectAccreditStatus,
    TagNameCopy,
    abnormalCom,
    DynamicPopover,
    KjTable,
    autoShebaoDetail,
    autoHomeSelect,
    wechatMessage,
    shebaoVerifySelect
  },
  props: {},
  data() {
    return {
      listQuery: {
        page: 1,
        limit: 20,
        name: "",
        period: currentAccountPeriod(),
        statusTotal: null,
        ywlx: 6,
        countSheBao: '1'
      },
      contentStyleObj: {},
      list: [],
      total: 0,
      loading: false,
      sels: [],
      startType: 0,
      dialogTableVisible: false,
      activeName: '1',
      daochuLoading: false,
      pageStatus: true,
      havingTask: false,
      info: {
        countTotal: 0,
        countEmpower: 0,
        countOk: 0,
        countDqr: 0,
        countErr: 0,
        countJxz: 0,
        countWks: 0,
        countYc: 0,
        countLsb: 0,
        countSkSb: 0,
        countWwc: 0
      },
      gridData: [],
      sendLoading: false
    };
  },
  activated() {
    this.pageStatus = true
  },
  deactivated() {
    this.pageStatus = false
  },
  beforeRouteLeave(to, from, next) {
    this.pageStatus = false
    next()
    // ...
  },
  created() {
    this.listQuery.limit = localStorage.getItem("autoHomeIndex")
      ? localStorage.getItem("autoHomeIndex") * 1
      : 20;
  },
  mounted() {
    this.contentStyleObj = this.$getHeight(326);
    this.getList();
    // this.initBus();
    this.getInfo()
  },
  methods: {
    getInfo() {
      automaticSocialStat(this.listQuery).then(res => {
        if (res.data.msg == 'success') {
          this.info = res.data.data
        }
      })
    },
    getNewList() {
      this.getList()
      this.getInfo()
    },
    getNewListTotal(e) {
      this.listQuery.statusTotal = e ? e : null
      this.getNewList()
    },
    initBus() {
      this.$bus.off("refreshTask")
      this.$bus.on("refreshTask", (val) => {
        if (!this.pageStatus) {
          return
        }
        if (this.sels.length != 0) {
          return
        }
        if (this.list.length == 0) {
          return
        }
        autoSocialQrList(this.listQuery).then(res => {
          this.list = res.data.data.list ? res.data.data.list : [];
          this.total = res.data.data.total;
        })
      });
    },
    getList() {
      this.loading = true
      autoSocialQrList(this.listQuery).then((res) => {
        this.loading = false
        if (res.data.msg == "success") {
          this.list = res.data.data.list ? res.data.data.list : [];
          this.total = res.data.data.total;
        }
      });
    },
    handleSelectionChange(e) {
      this.sels = e
    },
    handleClick(tab) {
      this.listQuery.countSheBao = tab.props.name == '3' ? '' : tab.props.name
      this.getList()
    },
    cancel() {
      let originLimit = this.listQuery.limit
      let originCountSheBao = this.listQuery.countSheBao
      this.listQuery = {
        page: 1,
        limit: originLimit,
        name: "",
        period: currentAccountPeriod(),
        countSheBao: originCountSheBao,
        ywlx: 6
      }
      this.getList()
    },
    getWjz() {
      this.$refs.abnormalCom.init(this.listQuery.period)
    },
    // 列表点击
    poper(row) {
      this.gridData = []
      companyUserList({ comId: row.comId }).then((res) => {
        this.gridData = res.data.data.list;
      });
    },
    //社保查看
    checkShebao(row) {
      let param = {
        comId: row.comId,
        period: this.listQuery.period,
        tableName: 'tax-cj-shebao',
        comName: row.name,
      }
      this.$refs.autoShebaoDetail.openDialog(param)
    },
    setWechat(row) {
      this.$prompt("请输入微信昵称/微信群名称", "绑定微信昵称", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputValue: row.wxNc,
        beforeClose: (action, instance, done) => {
          if (action == "confirm") {
            let value = instance.inputValue
            let param = {
              ids: [row.comId],
              wxNc: value,
            }
            companyEaSetWxNc(param).then((res) => {
              done()
              if (res.data.msg == "success") {
                this.$qzfMessage('设置成功')
                this.getList()
              }
            })
          } else {
            done()
          }
        },
      })
    },
    copyTax() {
      if (this.sels.length == 0) {
        this.$qzfMessage("请选择公司", 1);
        return;
      }
      let year = this.listQuery.period.slice(0, 4)
      let month = this.listQuery.period.slice(4, 6)
      // 基础字符串数组
      let rows = [];
      this.sels.forEach((v) => {
        let row = `公司名称：${v.name}\r缴费月份：${year}年${month}月\r缴费人次：${v.peopleCount}人次\r缴费总金额：${v.amount}元\r其中：单位承担：${v.shebaoDw}元\r\t\t\t\t\t\t\t\t\t个人承担：${v.shebaoGr}元`
        rows.push(row)
      })
      let str = `【社医保对账单】\r` + rows.join("\r") + `\r（以上信息请收悉，并确保银行账户有足够余额扣款）`
      this.$copyComName(str);
    },
    sendTax() {
      if (this.sels.length == 0) {
        this.$qzfMessage("请选择公司", 1);
        return;
      }
      let fail = this.sels.find(v => !v.wxNc)
      if (fail) {
        this.$qzfMessage(`${fail.name}未绑定微信，请重新选择`, 1)
        return
      }
      let year = this.listQuery.period.slice(0, 4)
      let month = this.listQuery.period.slice(4, 6)
      let that = this;
      this.sendLoading = true
      let arr = []
      this.sels.forEach((v => {
        let row = `【社医保对账单】\n公司名称：${v.name}\n缴费月份：${year}年${month}月\n缴费人次：${v.peopleCount}人次\n缴费总金额：${v.amount}元\n其中：单位承担：${v.shebaoDw}元\n\t\t\t\t\t\t\t\t\t个人承担：${v.shebaoGr}元\n（以上信息请收悉，并确保银行账户有足够余额扣款）`;
        arr.push({
          wxName: v.wxNc,
          msg: row
        })
      }))
      this.sendLoading = false
      axios
        .post("http://localhost:8002/doTask/wx", arr)
        .then((res) => {
        })
        .catch(function () {
          setTimeout(() => {
            that.$refs.loginTaxAlert.dialogVisible = true;
          }, 1000);
        });
    },
    //绑定微信
    bindWechat() {
      this.$refs.wechatMessage.init()
    },
    confirmAll() {
      if (this.sels.length == 0) {
        this.$qzfMessage('请选择公司', 1);
        return
      }
      const arr = this.sels.filter(v => v.shebaoqr_status == 2)
      if (arr && arr.length != 0) {
        this.$refs.shebaoVerifySelect.init(arr, this.listQuery.period)
      } else {
        this.$qzfMessage('您所勾选的公司中无待确认的社保信息', 1);
      }
    },
    confirmAllPay() {
      if (this.sels.length == 0) {
        this.$qzfMessage('请选择公司', 1);
        return
      }
      const arr = this.sels.filter(v => v.shebaokkqr_status == 2)
      if (arr && arr.length != 0) {
        this.$confirm('确定要批量扣款确认吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let ids = arr.map((v) => ({
            comId: v.comId,
            period: this.listQuery.period,
            type: "shebaokkqr",
          }));
          confirmAutomaticShebao(ids).then(res => {
            if (res.data.msg == 'success') {
              this.$qzfMessage('操作成功');
              this.getList()
            }
          })
        });
      } else {
        this.$qzfMessage('您所勾选的公司中不存在需要扣款确认的公司', 1);
      }
    }
  },
};
</script>

<style scoped lang="scss">
.top_select {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.item_icon {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  i {
    display: inline-block;
    line-height: 13px;
    font-size: 13px;
    margin-right: 2px;
  }

  p {
    display: inline-block;
    font-size: 13px;
    color: #333;
  }
}

.text_width {
  width: 52px;
  display: inline-block
}

.icon-tiaoguo1 {
  color: #17a2b8;
}

.style_form .el-form-item {
  margin-bottom: 10px;
}

.icon-a-xinjianzidingyichicun21x1 {
  font-size: 15px;
  margin-right: 2px;
}

.icon-jiantou-you {
  font-weight: 600;
}

.auto_content_size {
  width: 91%;
  margin-left: 10px;
}

.icon-weixintuisong {
  color: #15ba11;
  font-size: 13px;
  margin-left: 8px;
  cursor: pointer;
  position: relative;
  top: 2px;
}
</style>
