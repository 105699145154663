<template>
  <div class="app-container clearfix">
    <div class="new_table clearfix">
      <!-- 弹框 -->
      <el-dialog
        :close-on-click-modal="false"
        destroy-on-close
        :title="textMap[dialogStatus]"
        v-model="dialogFormVisible"
        width="500px"
      >
        <el-form
          ref="dataForm"
          :rules="rules"
          :model="temp"
          label-position="right"
          label-width="120px"
          style="width: 100%"
        >
          <el-form-item label="角色名称:" prop="name">
            <el-input
              v-model="temp.name"
              placeholder="请输入内容"
              size="small"
              style="width:300px"
            ></el-input>
          </el-form-item>
          <el-form-item label="备注:">
            <el-input
              v-model="temp.remark"
              placeholder="请输入备注"
              size="small"
              style="width:300px"
            ></el-input>
          </el-form-item>

          <!-- <el-form-item v-if="dialogStatus == 'update'" label="按钮权限控制:">
            <el-scrollbar :style="{ width: '100%', height: contentStyleObj }">
              <div>
                <el-row class="title_box">
                  <el-col :span="3">一级菜单</el-col>
                  <el-col :span="21">
                    <div class="borders_4">
                      <el-row>
                        <el-col :span="4">二级菜单</el-col>
                        <el-col :span="20">
                          <el-col :span="24">
                            <div class="borders_3">
                              <el-row>
                                <el-col :span="24">按钮</el-col>
                              </el-row>
                            </div>
                          </el-col>
                        </el-col>
                      </el-row>
                    </div>
                  </el-col>
                </el-row>
                <el-row v-for="(item, index) in list1" :key="index" class="box">
                  <el-col :span="3">
                    <div class="borders" style="cursor: pointer;">
                      <el-popconfirm
                        confirmButtonText="确定"
                        cancelButtonText="取消"
                        icon="InfoFilled"
                        icon-color="red"
                        :title="'确认修改' + item.name1 + '下按钮吗?'"
                        style="width:220px"
                        @confirm="chnegeName1(item)"
                        @cancel="cancelEvent"
                        width="220px"
                      >
                        <template #reference>
                          <el-tag size="medium">{{ item.name1 }}</el-tag>
                        </template>
                      </el-popconfirm>
                    </div>
                  </el-col>
                  <el-col :span="21">
                    <div class="borders_4">
                      <el-row
                        v-for="(item2, index2) in item.children"
                        :key="index2"
                      >
                        <el-col :span="4">
                          <div class="borders" style="cursor: pointer;">
                            <el-popconfirm
                              confirmButtonText="确定"
                              cancelButtonText="取消"
                              icon="InfoFilled"
                              icon-color="red"
                              :title="'确认修改' + item2.name2 + '下按钮吗?'"
                              style="width:220px"
                              @confirm="chnegeName2(item2)"
                              @cancel="cancelEvent"
                              width="220px"
                            >
                              <template #reference>
                                <el-tag type="warning">{{
                                  item2.name2
                                }}</el-tag>
                              </template>
                            </el-popconfirm>
                          </div>
                        </el-col>
                        <el-col :span="20">
                          <div class="borders">
                            <el-col :span="24">
                              <div class="borders_3">
                                <el-row>
                                  <el-col :span="24">
                                    <div class="borders">
                                      <span
                                        v-for="(item3,
                                        index3) in item2.childrens"
                                        :key="index3"
                                      >
                                        <el-check-tag
                                          @change="onChange(item3)"
                                          :checked="item3.checked"
                                          v-if="item3.name3"
                                          size="small"
                                          type="success"
                                          >{{ item3.name3 }}</el-check-tag
                                        ></span
                                      >
                                    </div>
                                  </el-col>
                                </el-row>
                              </div>
                            </el-col>
                          </div>
                        </el-col>
                      </el-row>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </el-scrollbar>
          </el-form-item> -->
        </el-form>

        <template #footer>
          <div class="dialog-footer">
            <el-button @click="dialogFormVisible = false" size="small"
              >取消</el-button
            >
            <el-button
              size="small"
              type="primary"
              @click="dialogStatus === 'create' ? createData() : updateData()"
              >确定</el-button
            >
          </div>
        </template>
      </el-dialog>
      <!-- 弹框 -->
      <div class="left_box">
        <div style="margin-bottom:20px;" class="grid-content bg-purple">
          <el-button @click="handleCreate()" type="primary" size="small" plain :disabled="!$buttonStatus('jsgl_xz')">
            <i class="iconfont icon-jiahao"></i> 新增</el-button
          >
            <el-button
              @click="handleUpdate()"
              type="primary"
              size="small"
              plain
              style="margin-left: 5px;"
            >
              <i class="iconfont icon-bianji"></i> 编辑</el-button
            >
          <el-button @click="del()" type="danger" size="small" plain :disabled="!$buttonStatus('jsgl_sc')" style="margin-left: 5px;"
            ><i class="iconfont icon-shanchu"></i> 删除</el-button
          >
        </div>
        <div class="left_tree">
          <h5 class="jsgl">角色管理</h5>
          <div v-for="(item, index) in list" :key="index" class="item_p">
            <i
              :class="{ curs: roleId == item.id }"
              class="el-icon-caret-right"
            ></i>
            <p @click="clickLeft(item.id)" :class="{ curs: roleId == item.id }">
              {{ item.name }}
            </p>
          </div>
        </div>
      </div>
      <div class="right_box">
        <!-- <el-scrollbar :height="contentStyleObj1"> -->
        <!-- <el-tree
          ref="tree"
          :data="data1"
          show-checkbox
          default-expand-all
          node-key="id"
          highlight-current
          :props="defaultProps"
          v-loading="loading"
        /> -->

        <el-table
          :data="data1"
          style="width: 100%"
          border
          :height="contentStyleObj1"
          v-loading="loading"
          stripe
          row-key="id"
          :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
          default-expand-all
        >
          <template #empty>
            <el-empty :image-size="150" description="没有数据"></el-empty>
          </template>
          <el-table-column prop="name" label="菜单" width="200">
            <template #default="scope">
              <el-checkbox
                v-model="scope.row.flag"
                size="small"
                v-if="scope.row.level == 1"
                @change="updateChecked(scope.row)"
                ><span style="font-weight: 600;">{{
                  scope.row.name
                }}</span></el-checkbox
              >
              <el-checkbox
                v-model="scope.row.flag"
                size="small"
                :label="scope.row.name"
                v-else
              ></el-checkbox>
            </template>
          </el-table-column>
          <el-table-column label="功能菜单" min-width="300">
            <template #default="scope">
              <div
                v-if="
                  scope.row.buttons.items && scope.row.buttons.items.length != 0
                "
              >
                <span
                  v-for="(item, index) in scope.row.buttons.items"
                  class="span_style"
                >
                  <el-check-tag
                    @change="onChange(item)"
                    :checked="item.flag"
                    size="small"
                    type="success"
                    >{{ item.buttonName }}</el-check-tag
                  >
                </span>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div style="margin-top: 10px;text-align: right;">
          <el-button @click="createRole()" type="primary" size="small" :disabled="!$buttonStatus('jsgl_bc')"
            ><i class="iconfont icon-baocun"></i> 保存</el-button
          >
        </div>

        <!-- </el-scrollbar> -->
      </div>
    </div>
  </div>
</template>

<script>
import {
  getSysRoles,
  saveSysRole,
  DeleteSysRole,
  getFunctionList,
  roleBingFunction,
  sysButtonSave,
  sysButtonRoleList,
} from "@/api/sys";
export default {
  name: "user-role",
  data() {
    return {
      list: [],
      roleId: 0,
      select: [],
      buttons: [],
      buttonRole: [],
      dialogFormVisible: false,
      loading: false,
      dialogStatus: "",
      textMap: {
        update: "修改",
        create: "新增",
      },
      arrs: [],
      temp: {
        name: "",
      },
      rules: {
        name: [
          { required: true, message: "角色名不能为空", trigger: "change" },
        ],
      },
      data1: [],
      defaultProps: {
        children: "children",
        label: "name",
      },
      list1: [],
      list2: [],
      contentStyleObj: "",
      contentStyleObj1: {},
    };
  },
  mounted() {
    this.getList();
    this.contentStyleObj = this.$getHeight(300);
    this.contentStyleObj1 = this.$getHeight(210);
  },
  methods: {
    getList() {
      getSysRoles({}).then((res) => {
        this.list = res.data.data.list;
        this.roleId = res.data.data.list[0].id;
        this.loading = true;
        this.getList2();
      });
    },
    getList2() {
      getFunctionList({ id: this.roleId }).then((res) => {
        this.data1 = res.data.data.list;
        this.select = res.data.data.select;
        this.data1.map((v) => {
          if (v.buttons.items && v.buttons.items.length != 0) {
            v.buttons.items.map((e) => {
              e.buttonName = e.buttonName.split("-");
              e.buttonName = e.buttonName[2];
            });
          }
          if (v.children && v.children.length != 0) {
            v.children.map((k) => {
              if (k.buttons.items && k.buttons.items.length != 0) {
                k.buttons.items.map((e) => {
                  e.buttonName = e.buttonName.split("-");
                  e.buttonName = e.buttonName[2];
                });
              }
            });
          }
        });
        // //console.log(this.data1);

        // this.setCheckedKeys();
      });
      this.loading = false;
    },
    //树状回显
    setCheckedKeys() {
      this.$refs.tree.setCheckedKeys(this.select);
    },
    // 右侧树状保存
    createRole() {
      /////console.log(this.data1);
      roleBingFunction({ roleId: this.roleId, list: this.data1 }).then(
        (res) => {
          if (res.data.msg == "success") {
            this.$message({
              message: "保存成功",
              type: "success",
            });
            this.getList2();
          }
        }
      );
      return;
    },
    resetTemp() {
      this.temp = {
        name: "",
        remark: "",
      };
    },
    handleCreate() {
      this.resetTemp();
      this.dialogStatus = "create";
      this.dialogFormVisible = true;
      this.$nextTick(() => {
        this.$refs["dataForm"].clearValidate();
      });
    },
    createData() {
      this.$refs["dataForm"].validate((valid) => {
        if (valid) {
          saveSysRole(this.temp).then((res) => {
            this.dialogFormVisible = false;
            if (res.data.msg == "success") {
              this.$qzfMessage("保存成功");
              this.$bus.emit("addUpdate");
              this.getList();
            }
          });
        }
      });
    },
    clickLeft(id) {
      this.roleId = id;
      this.getList2();
    },
    handleUpdate() {
      let items = {};
      //遍历根据id找item
      this.list.map((v) => {
        if (this.roleId == v.id) {
          items = v;
        }
      });
      //查询权限按钮
      sysButtonRoleList({ roleId: this.roleId }).then((res) => {
        this.buttons = res.data.data.buttonList;
        //start

        // let list1 = [];
        // res.data.data.buttonList.map((v) => {
        //   list1.push({
        //     name1: v.remark,
        //   });
        // });

        // //remark去重
        // let newArr = [];
        // for (let i = 0; i < list1.length; i++) {
        //   if (newArr.indexOf(list1[i].name1) == -1) {
        //     newArr.push(list1[i].name1);
        //   } else {
        //     list1.splice(i, 1);
        //     i--;
        //   }
        // }
        // //二级菜单去重
        // this.list1 = list1;
        // this.list1.forEach((item) => {
        //   item.children = [];
        // });
        // let list2 = [];
        // res.data.data.buttonList.map((v) => {
        //   v.buttonName = v.buttonName.split("-");
        //   list2.push({
        //     name1: v.buttonName[0],
        //     name2: v.buttonName[1],
        //   });
        // });
        // this.list1.map((v) => {
        //   list2.map((item) => {
        //     if (v.name1 == item.name1) {
        //       v.children.push({
        //         name2: item.name2,
        //       });
        //     }
        //   });
        // });
        // this.list1 = this.scIndex(this.list1);
        // //二级菜单去重end
        // //三级菜单push
        // this.list1.map((v) => {
        //   v.children.forEach((item) => {
        //     item.childrens = [];
        //   });
        // });
        // let list3 = [];
        // res.data.data.buttonList.map((v) => {
        //   list3.push({
        //     name2: v.buttonName[1],
        //     name3: v.buttonName[2],
        //     name1: v.buttonName[0],
        //     id: v.id,
        //   });
        // });
        // this.list1.map((v) => {
        //   v.children.map((e) => {
        //     list3.map((item) => {
        //       if (v.name1 == item.name1) {
        //         if (e.name2 == item.name2) {
        //           e.childrens.push({
        //             name3: item.name3,
        //             id: item.id,
        //           });
        //         }
        //       }
        //     });
        //   });
        // });
        //end
        this.buttonRole = res.data.data.list;
        // this.list1.map((v) => {
        //   v.children.map((e) => {
        //     e.childrens.map((f) => {
        //       f.checked = true;
        //       this.buttonRole.map((item) => {
        //         if (item == f.id) {
        //           f.checked = false;
        //         }
        //       });
        //     });
        //   });
        // });
      });

      // return
      this.temp = items;
      //  // this.temp = Object.assign({}) // copy obj
      this.dialogStatus = "update";
      this.dialogFormVisible = true;
      this.$nextTick(() => {
        this.$refs["dataForm"].clearValidate();
      });
    },
    chnegeName1(a) {
      a.children.map((v) => {
        v.childrens.map((vv) => {
          vv.checked = !vv.checked;
        });
      });
    },
    chnegeName2(a) {
      a.childrens.map((v) => {
        v.checked = !v.checked;
      });
    },
    scIndex(list) {
      let arr2 = [];
      list.map((v) => {
        let arr = [
          ...new Map(v.children.map((item) => [item.name2, item])).values(),
        ];
        arr2.push({
          name1: v.name1,
          children: arr,
        });
      });
      return arr2;
    },
    updateData() {
      this.$refs["dataForm"].validate((valid) => {
        if (valid) {
          // let items = [];
          // this.list1.map((v) => {
          //   v.children.map((e) => {
          //     e.childrens.map((item) => {
          //       if (!item.checked) {
          //         items.push(item.id);
          //       }
          //     });
          //   });
          // });
          const tempData = Object.assign({}, this.temp);
          saveSysRole(tempData).then((res) => {
            this.getList();
            this.dialogFormVisible = false;
            if (res.data.msg == "success") {
              this.$qzfMessage("保存成功");
              this.$bus.emit("addUpdate");
              this.getList();
            }
          });
          let param = {
            roleId: this.roleId,
            items: this.buttonRole 
          };
          sysButtonSave(param);
        }
      });
    },
    del() {
      this.$confirm("确认删除该角色", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        DeleteSysRole({ id: this.roleId }).then((res) => {
          if (res.data.msg == "success") {
            this.$qzfMessage("删除成功");
            this.$bus.emit("addUpdate");
            this.getList();
          }
        });
      });
    },
    onChange(item) {
      //item.checked = !item.checked;
      item.flag = !item.flag;
    },
    updateChecked(item){
      item.children.map(v=>{
        if(item.flag){
          v.flag = true
        }else{
          v.flag = false
        }  
      })
    },
  },
};
</script>

<style lang="scss" scoped>
.left_box .el-button {
  width: 55px;
  // padding: 7px 0 !important;
}
.bottom_big {
  height: 660px;
  overflow: hidden;
  padding-left: 12px;
}
.amod_ad {
  animation: ad 0.3s 0s both;
}
.amod_more {
  animation: more 0.3s 0s both;
}
.amod {
  // margin-top: 60%;
  height: 0;
}
@-webkit-keyframes ad {
  0% {
    height: 660px;
  }

  100% {
    height: 0px;
  }
}
@-webkit-keyframes more {
  0% {
    height: 0px;
  }
  100% {
    height: 660px;
  }
}
.app-container {
  position: relative;
  overflow-y: auto;
}
.title_box {
  width: 100%;
  line-height: 36px;
  height: 36px;
  font-size: 15px;
  color: #333;
  border: 1px solid #eee;
  border-bottom: none;
  .el-col {
    padding-left: 5px;
  }
  .el-row {
    border-bottom: none;
  }
}
.new_table {
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  min-height: calc(100vh - 214px);
}
.right_box {
  float: left;
  width: calc(100% - 180px);
  // min-height: calc(100vh - 214px);
  padding-left: 22px;
  border-left: 5px solid #e6eef8;
  // overflow-y: auto;
  // margin-top: 10px;
  .el-tree {
    padding-left: 20px;
    padding-top: 20px;
  }
}
.left_box {
  float: left;
  width: 180px;
  min-height: calc(100vh - 146px);
  // padding: 10px;
  overflow-y: auto;
  // text-align: center;
  .btn {
    display: flex;
    justify-content: space-evenly;
  }
}
.borders_6 {
  border-left: 1px solid #eee;
}
.left_tree {
  text-align: left;
  //padding-left: 15px;
  h5 {
    font-size: 19px;
    font-weight: 600;
    padding-bottom: 15px;
    color: #39b0d2;
    line-height: 30px;
    cursor: pointer;
    i {
      color: var(--themeColor, #17a2b8) !important;
      line-height: 30px !important;
      transition: transform 0.3s ease-in-out;
    }
  }
  i {
    display: inline-block;
    font-size: 14px;
    color: #c0c4cc;
    line-height: 22px;
    margin-right: 2px;
  }

  p {
    display: inline-block;
    cursor: pointer;
    transition: all 0.4s;
    font-size: 14px;
    line-height: 30px;
  }
}
.el-checkbox {
  //width: 160px !important;
  //margin-right: 20px;
  //margin-bottom: 8px;
}
.el-checkbox:nth-child(4n-1) {
  margin-right: 0;
}
.el-checkbox + .el-checkbox {
  margin-left: 0;
}
.el-col {
  padding-left: 5px;
}
.borders {
  // height: 30px;
  line-height: 42px;
  .el-row {
    border-right: 1px solid #eee;
  }
  .el-row:last-child {
    border-bottom: none;
  }
  .el-row:nth-child(1) {
    border-top: 0px;
  }
}
// .el-row:last-child {
//   border-bottom: none;
// }
.el-row {
  border-bottom: 1px solid #eee;
}
.el-col-9 .borders_4 {
  border-left: 1px solid #eee;
}
.bigborder {
  border: 1px solid #ccc;
  min-height: calc(100vh - 214px);
  // margin: 15px 0 20px 0;
  margin: 15px 0 0 0;
}
.borders_3 {
  border-left: 1px solid #eee;
}
.curs {
  // background: #F15A24;
  color: var(--themeColor, #17a2b8) !important;
}
.borders_4 {
  border-left: 1px solid #eee;
}
:deep(.el-dialog) {
  margin: 40px auto;
}
:deep(.el-check-tag) {
  padding: 5px 10px;
  margin-right: 10px;
}

// .box .el-row:first-child{
//   border-top: 1px solid #eee;
// }
// .box:first-child {
//   border-top: 1px solid #eee;
// }
.box .el-col-3:first-child {
  border-left: 1px solid #eee;
}
.el-table :deep(.cell) {
  white-space: normal;
}
.span_style {
  display: inline-block;
  margin-top: 3px;
}
.el-check-tag.is-checked{
  background-color: #f0f9f8;
  color: #17a2b8;
  border: 1px solid #17a2b8;
}
.el-check-tag{
  font-weight: 500;
  font-size: 12px;
  padding: 3px 6px;
}
</style>
